import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import {
  FileService,
  MediaImageService,
  FileFile,
  MediaDocumentData,
  MediaImage,
  MediaImageDataRelationships,
  MediaRemoteVideoData,
  MediaRemoteVideoDataRelationships,
  NodeBlogData,
  MediaAudioData,
} from '@generated/content';

import { DocumentResolver } from '@services/resolvers';
import { HyphenatePipe } from '../../../pipes/hyphenate/hyphenate.pipe';
import { MediaResolver } from '@services/resolvers/auxiliary/media-resolver';
import { SpsLogger } from '@lib/utils';

import { GatedFormTypesEnum } from '@models/enums';
import { Card } from '@interfaces/config';
import { TImageConfig } from '@interfaces/media';

export interface CmsNodeLikeData {
  id?: string;
  attributes?: { [key: string]: any };
  relationships?: MediaImageDataRelationships | MediaRemoteVideoDataRelationships;
}

export interface CmsNodeLike extends CmsNodeLikeData {
  data?: CmsNodeLikeData;
}

@Injectable({
  providedIn: 'root',
})
export class AsyncCardResolver {
  constructor(private fileApi: FileService, private mediaImageApi: MediaImageService, private mediaResolver: MediaResolver) {}

  public async buildCardConfigForMediaDocument(node: MediaDocumentData): Promise<Card.ComponentConfig> {
    const config = this.getBaseCardConfigForMediaDocument(node);

  return  this.getChildNodeAttr(node, 'field_media_document')
      .then(fileNodeId => {
        if (fileNodeId) {
          const { href, target } = DocumentResolver.buildDocumentTarget(node.id);
          let urlWithLangCode = href+"?lang="+node.attributes.path.langcode;
          config.link = { href:urlWithLangCode, target, fileName: node.attributes.name };
        }
      })
      .then(_ => {
        config.sticky = node.attributes.field_sticky ?? false;
      })
      .then(_ => this.getChildNodeAttr(node, 'field_image_reference'))
      .then(_id => this.getImageConfigFor(_id, null))
      .then(imageConfig => ({ ...config, imageConfig }))
      .then(config => this.setGated(config, node))
      .catch(this.fail.bind(this));

    
      

  }

  public async buildCardConfigForMediaRemoteVideo(node: MediaRemoteVideoData): Promise<Card.ComponentConfig> {
    const config = this.getBaseCardConfigForRemoteVideo(node);

    return this.getChildNodeAttr(node, 'thumbnail')
      .then(_id => {
        return this.mediaResolver.getImageConfig(null, _id);
      })
      .then(imageConfig => ({ ...config, imageConfig, link: { ...config.link, text: 'Play' } }))
      .catch(this.fail.bind(this));
  }

  public async buildCardConfigFromBlogPage(node: NodeBlogData): Promise<Card.ComponentConfig> {
    const config = this.getBaseCardConfigForBlogPage(node);

    // get card image
    if (node.relationships?.field_teaser_image_page?.data?.id) {
      config.imageConfig = await this.getImageConfigFor(node.relationships?.field_teaser_image_page.data.id);
    }

    return config;
  }

  public async buildCardConfigForMediaAudio(node: MediaAudioData): Promise<Card.ComponentConfig> {
    const headline = node.attributes.field_title || 'Podcast';
    const fileFileNode = await this.getFileFileNode(node.relationships?.field_media_audio_file?.data?.id);
    const href = fileFileNode?.data?.attributes?.uri?.url;
    //let gated = (node.attributes.field_select_gated_form as GatedFormTypesEnum) ?? null;
    let gated = null
    const link = href ? { href, label: headline, text: 'Play',gated } : null;
  
    return {
      taxonomy: 'Podcast',
      headline,
      excerpt: node.attributes.field_description || '',
      imageConfig: {
        dataUrls: {
          

        small_600w: '/assets/images/podcast_cover_final.jpg',
          media_library: '/assets/images/podcast_cover_final.jpg',
          legacy: false,
        },
      },
      link,
    };
  }

  /* ----------------------------     Extraction helpers     -------------------------- */

  private async getImageConfigFor(mediaImageNodeId = null, fallback = undefined): Promise<TImageConfig> {
    if (!mediaImageNodeId) {
      return Promise.reject();
    }

    return this.mediaResolver.getImageConfig(mediaImageNodeId);
  }

  private getChildNodeAttr(node: CmsNodeLike, key: string): Promise<string>;
  private getChildNodeAttr(node: CmsNodeLike, key: string, attrs: string | string[] = ['id']): Promise<string | object> {
    attrs = Array.isArray(attrs) ? attrs : [attrs];
    let result: string = null;
    let attr: string;

    while (!result && attrs.length) {
      attr = attrs.shift();
      result = node?.relationships?.[key]?.data?.[attr];
    }

    if (result) {
      return Promise.resolve(result);
    }

    return this.fail(`getChildNodeAttr: did not find attr '${attr}' for key '${key}'.`);
  }

  private getNodeAttr<T>(data: CmsNodeLikeData = null, key: string, attrs: string | string[] = ['id']): Promise<T> {
    attrs = Array.isArray(attrs) ? attrs : [attrs];

    let result: string = null;
    let attr: string;

    while (!result && attrs.length) {
      attr = attrs.shift();
      result = data?.attributes?.[key]?.[attr];
    }

    if (result) {
      return Promise.resolve(result as T);
    }

    return this.fail(`getNodeAttr: Did not find attr '${attr}' for key '${key}'.`);
  }

  /* ----------------------------     Api related     -------------------------- */

  private async getMediaImageNode(nodeId: string): Promise<MediaImage> {
    return await firstValueFrom(this.mediaImageApi.mediaImageEntityGet(nodeId)).catch(e => Promise.reject(e));
  }

  private async getFileFileNode(nodeId: string): Promise<FileFile> {
    return await firstValueFrom(this.fileApi.fileFileEntityGet(nodeId)).catch(e => Promise.reject(e));
  }

  /* ----------------------------     Config helpers     -------------------------- */

  private getBaseCardConfigForBlogPage(node: NodeBlogData): Card.ComponentConfig {
    const {
      field_publication_date,
      field_teaser_headline_page,
      field_teaser_subheadline_page,
      field_teaser_link_page,
      field_industry_blog,
      created,
      path,
    } = node.attributes;

    return {
      nodeId: node.id,
      headline: HyphenatePipe.transform(field_teaser_headline_page),
      excerpt: HyphenatePipe.transform(field_teaser_subheadline_page),
      taxonomy: field_industry_blog,
      link: {
        href: `/${path?.langcode}${path?.alias}`,
        text: HyphenatePipe.transform(field_teaser_link_page?.title),
      },
      metas: {
        publishedAt: field_publication_date ? new Date(field_publication_date.toString()) : created ? new Date(created) : null,
      },
    };
  }

  private getBaseCardConfigForRemoteVideo(node: MediaRemoteVideoData): Card.ComponentConfig {
    const { changed, field_play_time, field_media_oembed_video } = node.attributes;

    return {
      ...this.getBaseCardConfig(node),
      taxonomy: 'Video',
      link: { href: field_media_oembed_video, external: true },
      imageConfig: {},
      metas: {
        publishedAt: changed ? new Date(changed) : null,
        readTime: field_play_time,
      },
    };
  }

  private getBaseCardConfigForMediaDocument(node: MediaDocumentData): Card.ComponentConfig {
    const { field_publishing_date, field_read_time, field_document_type } = node.attributes;

    const base = this.getBaseCardConfig(node);

    return {
      ...base,
      taxonomy: field_document_type,
      metas: {
        publishedAt: field_publishing_date ? new Date(field_publishing_date) : null,
        readTime: field_read_time,
      },
    };
  }

  private getBaseCardConfig(node: MediaDocumentData | MediaRemoteVideoData): Card.ComponentConfig {
  
    let excerpt = node.attributes.field_description;

    if("field_document_description" in node.attributes && node.attributes['field_document_description']){
      excerpt = node.attributes['field_document_description'].value;
    }
    return {
      nodeId: node.id,
      headline: HyphenatePipe.transform(node.attributes.field_title || node.attributes.name),
      excerpt: HyphenatePipe.transform(excerpt),
      link: null,
    };
  }

  private setGated(config: Card.ComponentConfig = null, node: MediaDocumentData = null): Card.ComponentConfig {
    // Use this to disable gated assets
    // return config

    // Use this to enable gated assets

    if (!config?.link || !node) {
      return config;
    }

 
    config.link.gated = (node.attributes.field_select_gated_form as GatedFormTypesEnum) ?? null;
  
    return config;
  }

  /* ----------------------------     Error handling     -------------------------- */

  private fail(error: string | Error): Promise<any> {
    const message = typeof error === 'string' ? error : error.toString();
    SpsLogger.warn(message);
    return Promise.reject(error);
  }
}
